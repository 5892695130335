<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="请选择" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>Whistle用户充值协议</h2>
		<p>本Whistle用户充值协议（以下称“本协议”）由您与CLICK ADVERTISING & MEDIA PTE. LTD.（以下简称“本公司”）共同缔结，本协议具有合同效力。 为保障您的合法权益，请您在同意并接受本协议所有条款前务必审慎阅读、充分理解各条款内容，特别是免除或限制本公司责任的条款（以下称“免责条款”）、对您的权利进行限制的条款（以下称“限制条款”）、约定争议解决方式和司法管辖的条款。 前述免责、限制及争议解决方式及其他重要条款将以黑体加粗的方式或其他合理方式提示您注意，您对该等条款的确认有可能导致您在特定情况下的被动、不便、损失，请您在确认同意本协议之前或在使用本公司软件服务之前务必阅读前述条款。</p>
		<p>如果您未满18周岁，请在您的法定监护人的陪同下阅读本协议，并在取得监护人对您使用本公司公司提供的服务及向本公司公司支付费用等行为的同意，并认可本协议、《个人信息保护政策》、《儿童用户协议》及《儿童个人信息保护政策》的全部条款内容后，方可使用本公司软件服务。如您为未成年人的法定监护人。如您是14周岁以下（含）的未成年人或其法定监护人。</p>
		<p>如果您对本协议或本公司软件服务有意见或建议，可与本协议中约定的本公司客户服务部门联系，我们会给予您帮助。 一旦您点击“同意”键、“接受”键或安装、使用、注册或以其他方式访问我们的服务，即视为您已详细阅读理解了本协议的全部内容，并同意遵守本协议的规定，所以请您务必在点击操作前仔细、完整地阅读本协议。</p>
		<h2>第一条 服务规则</h2>
		<p>1、“星豆”是本平台向您提供的用于在本平台上进行相关消费的虚拟道具，您可以用“星豆”购买虚拟礼物等本平台上产品或服务（具体可购买产品和服务请查看相应产品、服务页面说明），并将购买的虚拟礼物赠与主播或平台创作者。但是，“星豆”在任何情况下均不能兑换为人民币，请您务必根据自己的实际需求购买相应数量的“星豆”。</p>
		<p>2、在充值过程中，请您务必仔细确认自己的帐号并仔细选择相关操作选项。若因您自身输入帐号错误、操作不当或不了解、未充分了解充值计费方式等因素造成充错帐号、错选充值种类等情形而导致自身权益受损的，由此带来的损失由您自行承担，本平台不会作出任何补偿或赔偿。</p>
		<p>3、请您务必使用本平台指定的官方充值方式进行充值。若您使用非本平台所指定的充值方式或非法的方式进行充值，本平台不保证该充值顺利或者正确完成，同时可能引发其他风险，若因此造成您或任何第三方权益受损，由您自行应对处理并承担相应责任，本平台不会作出任何补偿或赔偿，同时本平台保留随时中止您的帐号部分或全部资格、冻结您的帐户余额、短期或永久封禁您的帐号及禁止您使用各项充值服务的权利。</p>
		<p>4、“星豆”充值属于本平台根据业务运营需要而提供的一次性服务，“星豆”并非存款、预付款或其他可无理由退还性质的商品或财产权利。用户充值成功后，本平台的充值服务即已提供完毕，本平台不再提供逆向兑换或转让服务，且无正当理由本平台亦不予提供退还服务（正当理由指本平台停止运营或停止向用户提供“星豆”及所有“星豆”相关功能、服务，或法律法规明确规定本平台应当退还），请您务必在具体操作前谨慎选择。</p>
		<p>5、如果本平台发现因系统故障或其他任何原因导致的处理错误，无论有利于本平台还是有利于您，本平台都有权在以电子邮件、短信或其他合理方式通知您后纠正该错误。如果该措施导致您实际收到的“星豆”数量少于您应获得的“星豆”，则本平台在确认该处理错误后会尽快将差额补足至您的帐户中。如果该错误导致您实际收到的“星豆”数量多于您应获得的“星豆”，则无论错误的性质和原因如何，本平台有权从您的帐户中直接扣除差额。</p>
		<p>6、根据公司产品功能，您的充值帐号在公司运营的其他产品、平台（以下称“关联平台”）购买的虚拟道具，可能在本平台与您购买的“星豆”合并计算并具备同等使用范围，此时，您在本平台使用关联平台虚拟道具的行为同样受本协议约束（无论前述关联平台虚拟道具在本平台被叫做“星豆”或其他名称）；反之，您的星豆通过上述方式在关联平台使用的，您在关联平台使用“星豆”的行为，将同时受关联平台充值协议及相关规则条款约束。</p>
		<p>7、各充值方式对应的渠道商可能会按其标准制定相关充值渠道手续费用，并在用户充值时收取，请用户务必注意各充值方式的渠道商服务手续费，并按自身需求选取充值方式。</p>
		<p>8、“星豆”购买后不能逆向兑换为人民币，且如无本协议约定的正当理由本平台亦不提供退还服务。</p>
		<h2>第二条 权利声明</h2>
		<p>1、您理解并同意，本平台有权基于交易安全等方面的考虑不时设定涉及交易的相关事项，包括但不限于交易限额、交易次数等。</p>
		<p>2、如充值过程中涉及由第三方提供的相关服务，则除遵守本协议约定外，您还需要同意并遵守该等第三方的协议及相关规则，在任何情况下，对于该第三方及其提供的相关服务而产生的争议由您自行与该第三方解决，本平台无需就此向您或该第三方承担任何责任。</p>
		<p>3、因您自身的原因导致本平台无法提供“星豆”购买服务或提供“星豆”购买服务时发生任何错误而产生的任何损失或责任，由您自行负责，本平台不承担责任，包括但不限于：</p>
		<ul>
			<li>•	（1）反对宪法确定的基本原则的；</li>
			<li>•	（2）因您的Whistle账号失效、丢失、被封停；</li>
			<li>•	（3）因您绑定的第三方支付机构账户的原因导致的损失或责任，包括您使用未经认证的第三方支付账户或使用非您本人的第三方支付账户，您的第三方支付账户被冻结、查封等；</li>
			<li>•	（4）您将密码告知他人导致的财产损失；</li>
			<li>•	（5）因您个人的故意或重大过失所造成的财产损失。</li>
		</ul>
		<p>4、因下列状况导致您无法使用本平台的充值服务或使您的任何虚拟财产丢失时，您同意本平台无须承担损害赔偿责任，该状况包括但不限于：</p>
		<ul>
			<li>•	（1）在本平台公告之系统停机维护、升级、调整期间；</li>
			<li>•	（2）电信通讯或设备出现故障不能进行数据传输的；</li>
			<li>•	（3）因台风、地震、海啸、洪水、停电、战争、恐怖袭击、政府管制等不可抗力之因素，造成本平台系统障碍不能执行业务的；</li>
			<li>•	（4）由于黑客攻击、电信部门技术调整或故障、网站升级、相关第三方的问题等原因而造成的服务中断或者延迟。</li>
		</ul>
		<p>5、在任何情况下，本平台均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害承担责任。本平台对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您因使用本平台提供的充值服务而支付的费用。</p>
	
		<h2>第三条 处罚规则</h2>
		<p>1、如发生下列任何一种情形，本平台有权随时中断或终止向您提供本协议项下的网络服务（而无需另行单独通知您），且无需承担因充值服务中断或终止而给您或任何第三方造成损失的任何责任：</p>
		<ul>
			<li>•	（1）您提供的个人资料不真实；</li>
			<li>•	（2）您违反本协议、《用户服务协议》、或其他本平台对用户的管理规定；</li>
			<li>•	（3）您以营利、经营等非个人使用目的购买、使用“星豆”。</li>
		</ul>
		<p>2、用户在使用本平台提供的充值服务时，如出现任何的涉嫌犯罪、违法违规、违反社会主义道德风尚、违反《Whistle软件许可及用户协议》、本协议或其他本平台对用户的管理规定的情形，本平台有权视您行为的性质及严重程度决定对您的帐号采取暂时或永久封禁措施。帐号封禁后至解禁（如有）前，您帐户上剩余的“星豆”将被暂时冻结或全部扣除，不可继续用于购买本平台的产品及/或服务，同时不予返还您购买“星豆”时的现金价值。</p>
	
		<h2>第四条 争议处理</h2>
		<p>1、本协议签订地为中华人民共和国广东省深圳市南山区。</p>
		<p>2、本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律。</p>
		<p>3、若您和本公司之间因本协议发生任何纠纷或争议，首先应友好协商解决； 协商不成的，您与本公司同意，将纠纷或争议提交至深圳国际仲裁院进行仲裁，并同意使用该仲裁院的仲裁规则。</p>
		<p>4、本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
	
		<h2>第五条 其他</h2>
		<p>1、为了向您提供更好的服务，或者基于法律规定、主管部门要求、业务开展情况等因素的更新与变化，本平台将适时对本协议内容进行修订，该等修订内容一经公布立即生效，本平台将通过本平台发出更新版本，您可通过“星豆”充值页面随时查阅最新版本的协议条款。若您在本协议内容发生修订后继续使用本平台提供的充值服务的，则视为您同意最新修订的协议内容；否则请您立即停止充值。</p>
		<p>2、您理解并同意，本平台提供的充值服务是按照现有技术和条件所能达到的现状提供的，本平台会尽最大努力向您提供充值服务，确保充值服务的连贯性和安全性。但您亦知悉并认可，本平台不能随时或始终预见和防范技术及其他风险，包括但不限于因不可抗力、网络原因、第三方服务瑕疵、第三方网站等原因可能导致的服务中断、不能正常使用充值服务以及其他的损失和风险；此外，除前述情况外，本平台有权视交易安全、运营规划等具体情况随时中断、中止或终止本协议项下充值服务，并将以本平台页面公告或其他适当方式向用户发出通知（而不再另行单独通知您）。</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [
					{
						value: '中文',
						label: '中文'
					}, {
						value: 'English',
						label: 'English'
					}, {
						value: '한국인',
						label: '한국인'
					}, {
						value: '日本語',
						label: '日本語'
					}
				],
				value: '',
				language: ''
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			if (this.language == 'zh_CN') {
				this.$router.push({
					path: '/RechargeAgreementZh'
				})
			}
			if (this.language == 'en_US') {
				this.$router.push({
					path: '/RechargeAgreementEn'
				})
			}
		},
		methods: {
			clickChange(value) {
				this.value = value;
				if (value == '中文') {
					this.$router.push({
						path: '/RechargeAgreementZh'
					})
				}
				if (value == 'English') {
					this.$router.push({
						path: '/RechargeAgreementEn'
					})
				}
				if (value == '한국인') {
					this.$router.push({
						path: '/RechargeAgreementKo'
					})
				}
				if (value == '日本語') {
					this.$router.push({
						path: '/RechargeAgreementJa'
					})
				}
			},
		}
	}
</script>

<style>
	.container {
		padding: 0.1rem 0.3rem;		
	}
	
	.lang {
		text-align: right;
		margin-bottom: 0.3rem;
	}

	h1,
	h2 {
		color: #333;
		font-size: 0.5rem;
	}

	h3 {
		margin-top: 20px;
		font-size: 0.4rem;
	}

	p {
		margin-bottom: 15px;
		font-size: 0.3rem;
	}
	
	li {
		font-size: 0.3rem;
	}
</style>